<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.notice')}}
    </navi-bar>
    <cs-nav-mobile/>
    <div class="writebtn mt10">
<!--      <select class="select">-->
<!--        <option>공지분류</option>-->
<!--        <option>전체</option>-->
<!--        <option>라이브카지노</option>-->
<!--        <option>슬롯</option>-->
<!--        <option>충전/환전</option>-->
<!--        <option>베팅관련</option>-->
<!--        <option>기타</option>-->
<!--      </select>-->
    </div>
    <div class="msgwrap qnalistwrap">
      <ul>
        <li>
          <a>
            <em class="waiticon">일반</em>
            <span class="subject"># 게임공지 #<em class="newicon">N</em></span>
            <span class="date">2025.04.02</span>
          </a>
          <span class="arrow" @click="readOpena = !readOpena">
            <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="readOpena"/>
            <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!readOpena"/>
          </span>
        </li>
        <li class="qnalistread progress" v-if="readOpena">
          <p class="writing mb45">
            <pre>
@에볼루션금지게임목록@

TV쇼 게임 카테고리 전게임
룰렛전게임
바카라훔쳐보기
1인칭 풍성한 골든바카라
슈퍼 내부외부
판탄
위 게임은 게임하여 당첨이 되더라도 베팅금 당첨금 모두 몰수처리됩니다
            </pre>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <a>
            <em class="waiticon">일반</em>
            <span class="subject"># 비정상 게임이용 제재안내 #<em class="newicon">N</em></span>
            <span class="date">2025.04.02</span>
          </a>
          <span class="arrow" @click="readOpenb = !readOpenb">
            <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="readOpenb"/>
            <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!readOpenb"/>
          </span>
        </li>
        <li class="qnalistread progress" v-if="readOpenb">
          <p class="writing mb45">
            <pre>
저희 카지노는 정상적인 라이브 카지노와 동일한 룰로 운영되고있습니다.
이러한 운영룰은 개개인이 정상적인 방법으로 게임하는 경우를 전제로 하는 것입니다.
따라서 개인 또는 다수에 의한, 조직적이고 지속반복적인 형태의 비정상적인
시스템 배팅 및 마틴 배팅,양방 배팅 등은 허용되지 않습니다.
회원님의 배팅 상황은 센터의 모니터링 과정에서 대부분 발견되지만,
설령 발견이 안되어도 회원님이 출금을 신청하시면 출금 절차상 반드시 모든 배팅 내역을 확인한 후에
출금이 진행되도록 되어있기에 이러한 비정상적인 배팅 내역은 반드시 발견되게 됩니다.

(구체적 사례 :예시)

-배팅의 50% 이상을 마틴배팅으로 게임을 진행하는 경우

-지속적으로 플레이어 혹은 지속적으로 뱅커에만 배팅을 하는경우

-회원 2인 이상이 동일 테이블또는 같은 회차에 마틴,양방배팅을 하는 경우

-배팅의 50% 이상을 찍어먹기 배팅으로 게임을 진행하는 경우

-본사 내부 또는 타 카지노와 게임을 진행하는 경우

-특정 프로그램 또는 특정 계산방식에 근거한 시스템 배팅을 하는 경우

-멀티플레이로 배팅을 하는 경우 ( 블랙잭 이용시  한테이블 한구멍만배팅가능)

이러한 비정상적인 배팅 사례가 발생할 경우 사전 통보없이 회원님의 게임 전부와 원금을 전액 몰수(원칙)
할 수 있습니다. 모니터링 과정엣 발견된 경우 1회 경고를 하는 경우도 있지만, 심한 경우 발견 즉시 사전 통보없이
게임을 중지(무효)시키고 원금 몰수 ,계정삭제등을 진행 합니다.

본사의 모든 게임을 정상적인 방법으로 즐겨 주시기 바라오며, 저희 운영진들은 회원님이 편안하게
게임을 즐기실 수 있도록 더욱 최선을 다하겠습니다. 감사합니다.
            </pre>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <a>
            <em class="waiticon">일반</em>
            <span class="subject"># 배팅한도 #<em class="newicon">N</em></span>
            <span class="date">2025.04.02</span>
          </a>
          <span class="arrow" @click="readOpenc = !readOpenc">
            <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="readOpenc"/>
            <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!readOpenc"/>
          </span>
        </li>
        <li class="qnalistread progress" v-if="readOpenc">
          <p class="writing mb45">
            <pre>
-카지노 배팅 상한 500만원

-카지노 당첨 상한 1천만원

- 라이트닝 바카라등 배당게임의 경우 최대당첨금액 500만원으로 제한합니다 그이상 당첨될 경우 회수처리됩니다

-사이드 배팅 (타이 페어 등) 어떠한 경우라도 1회배팅시에는 당첨금1천만원까지 적용됩니다.

-슬롯 배팅 상한 3만원 (프리스핀 100만원)

-슬롯 당첨 상한 3천만원 초과된 당첨금에 대하여서는 회수

해당 내용 꼭 참고 하시어 이용 부탁드리겠습니다.
            </pre>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <a>
            <em class="waiticon">일반</em>
            <span class="subject"># 충전 및 환전 안내사항 #<em class="newicon">N</em></span>
            <span class="date">2025.04.02</span>
          </a>
          <span class="arrow" @click="readOpend = !readOpend">
            <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="readOpend"/>
            <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!readOpend"/>
          </span>
        </li>
        <li class="qnalistread progress" v-if="readOpend">
          <p class="writing mb45">
            <pre>
-충전 신청시 고객센터 문의후 입금해 주시기바랍니다.
계좌가 수시로 변경되오니 불편하더라고 꼭 문의후 입금바랍니다. 계좌 변경으로 인한 잘못된 입금은
책임지지 않으니 필히 문의후 입금바랍니다.

-충전 신청시 만원단위로 입금 가능하며 최대 9백만까지 가능합니다

-하루 최대 출금액은 6천만원으로 제한합니다

-1회 최대 출금액은 2천만원 까지이며 재환전 출금시간은 직전 환전후 1시간 이후 가능합니다.

-은행점검 시간은 23:30~12:30 까지는 환전을 제한합니다

-환전은 롤링 100%후 환전이 가능합니다
            </pre>
          </p>
        </li>
      </ul>

      <!-- <template v-for="item in list" v-bind:key="item.boardIdx">
        <ul>
          <li>
            <a><- @click="onClickRead(item.boardIdx)" ->
              <em class="waiticon">{{$t('front.boardCategory.' + item.category)}}</em>
              <span class="subject">{{item.title}}<em class="newicon">N</em></span>
              <span class="date">{{dateFormatForMobile(item.regDate)}}</span>
            </a>
            <span class="arrow" @click="item.isContentOpen = !item.isContentOpen">
              <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="item.isContentOpen"/>
              <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!item.isContentOpen"/>
            </span><- icon_bottom_gr ->
          </li>
          <li class="qnalistread progress"  v-if="item.isContentOpen">
            <p class="writing mb45">{{ item.content }}</p>
          </li>
        </ul>
      </template> -->
    </div>

    <!--div class="searchwrap">
      <select class="searchselect">
        <option>제목</option>
        <option>날짜</option>
        <option>글쓴이</option>
      </select>
      <input class="searchinput" type="text" placeholder="검색어를 입력해 주세요.">
      <a class="searchicon"></a>
    </div-->
    <pagination-mobile v-if="pageInfo"
                       :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList()"
                       />
    <board-filter-mobile @onSearch="onSearch"/>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import CsNavMobile from '@/views_mobile/member/cs/nav'
import Notice from '@/views/member/cscenter/notice/list'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'
export default {
  name: 'Coupon',
  components: {
    CsNavMobile,
    NaviBar,
    PaginationMobile,
    BoardFilterMobile
  },
  mixins: [Notice]
}

</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style>
.writing pre {white-space: pre-wrap;line-height: 1.5em;}
</style>
